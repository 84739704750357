/* super teeney */
@media (max-width: 400px) {
  .facebook {
    width: 75%;
    float: right;
  }
  .mailIcon {
    width: 75%;
    float: right;
    margin-right: -4px;
  }
  .WhoAreThey {
    font-size: 1em;
  }
  .nextGigText {
    font-size: 20px;
    color: #ccc;
  }
  .font-21 {
    font-size: 16px;
  }
  .ls-5 {
    line-height: 1em !important;
    text-align: justify;
  }
  .socials h3 a {
    font-size: 1.5rem;
  }
  .bumpf {
    font-family: 'Roboto', Courier, monospace;
  }

  .logoTextContainer > h1 {
    text-align: center;
    color: white;
    font-family: 'Haettenschweiler', sans-serif;
    font-size: 14rem;
    padding: 5px;
    margin: 0;
  }
  .tallbucket {
    border: 3px solid red;
    height: 100px;
    width: 100% !important;
    /* margin: 2.5% !important; */
  }
  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }

  .playlistTitle {
    color: black !important;
    font-size: 2em !important;
  }

  .setCursor {
    width: 100%;
  }
  .card {
    background-color: #111;
    cursor: pointer;
    border: 1px solid #777;
    transition: all 0.3s;
    min-height: 152px; /* was 310px */
  }
  .card img {
    margin-top: -10px;
  }

  .cardBody p {
    font-size: 12px !important;
  }
  .cardHeader {
    min-height: 110px;
    overflow: hidden;
    border-radius: 10px;
  }
  .YTIframe {
    width: 100%;
    min-height: 400px;
  }
  .eventContainer {
    width: 86%;

    margin-left: 2%;
  }
  .eventDescription {
  }
  .eventLocation {
    font-size: 14px;
  }
  .eventDate {
  }
  .containerBG {
    position: relative;
    width: 100%;
    /* min-height: 16vh; */
  }
  .btn-outline-light {
    font-family: 'Haettenschweiler', sans-serif;
    font-size: 10px;
    width: 200px !important;
  }

  .nextGigHeader {
    font-size: 0.8em;
  }
  .nextGigText {
    font-size: 0.5em;
  }
  h1.playlistButtonHeader {
    display: inline-block;
    top: 25px;
    font-size: 2em;
  }
  .playlistButtonDescription {
    color: whitesmoke;
    top: 25px;
    font-size: 16px;
  }
}
/* XS only ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
@media (min-width: 400px) and (max-width: 576px) {
  .facebook {
    width: 75%;
    float: right;
  }
  .mailIcon {
    width: 75%;
    float: right;
  }
  .headerText {
    font-size: 4rem;
  }
  .socials h3 a {
    font-size: 1.5rem;
  }

  .logoTextContainer > h1 {
    text-align: center;
    color: white;
    font-family: 'Haettenschweiler', sans-serif;
    font-size: 14rem;
    padding: 5px;
    margin: 0;
  }
  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }

  .playlistTitle {
    color: black !important;
    font-size: 2em !important;
  }

  .setCursor {
    width: 100%;
  }

  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }
  .two {
    width: 80%;
  }

  .card {
    background-color: #111;
    cursor: pointer;
    border: 1px solid #777;
    transition: all 0.3s;
    min-height: 172px; /* was 310px */
  }
  .card img {
    margin-top: -10px;
  }

  .cardBody p {
    font-size: 12px !important;
  }
  .cardHeader {
    min-height: 170px;
    overflow: hidden;
    border-radius: 10px;
  }
  .YTIframe {
    width: 100%;
    min-height: 400px;
  }
  .eventContainer {
    width: 86%;

    margin: 2%;
  }
  .eventDescription {
  }
  .eventLocation {
    font-size: 14px;
  }
  .eventDate {
  }
  .ls-5 {
    line-height: 1em !important;
    text-align: justify;
  }
  .containerBG {
    position: relative;
    width: 100%;
    min-height: 16vh;
  }
  .btn-outline-light {
    font-family: 'Haettenschweiler', sans-serif;
    font-size: 20px;
    width: 250px !important;
  }
  .mobileIndieThieves h1 {
    font-size: 100px !important;
  }
  .buttonArea p {
    font-size: 0.6em;
  }
  .playlistbuttons p {
    font-size: 22px !important;
    margin-left: 20px;
    margin-top: -10px;
  }

  h1.playlistButtonHeader {
    font-size: 2.5em;
    display: inline-block;
  }
  h1.playlistButtonHeader {
    display: inline-block;
    top: 25px;
  }
  .playlistButtonDescription {
    color: whitesmoke;
    top: 25px;
  }
}
/* SM only =================================================================================================================================*/
@media (min-width: 576px) and (max-width: 768px) {
  .eventLocation {
    font-size: 14px;
  }

  .facebook {
    width: 40%;
    float: right;
  }
  .mailIcon {
    width: 45%;
    float: right;
  }
  .headerText {
    font-size: 4rem;
  }
  .socials h3 a {
    font-size: 1.5rem;
  }

  .logoTextContainer > h1 {
    text-align: center;
    color: white;
    font-family: 'Haettenschweiler', sans-serif;
    font-size: 14rem;
    padding: 5px;
    margin: 0;
  }
  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }

  .one {
    background-image: url('./images/TobyLeft.jpg');
    background-size: 150%;
    background-position: center center;
  }
  .two {
    background-image: url('./images/TobyAndJonMiddle.jpg');
    background-size: 110%;
    background-repeat: no-repeat;
    margin-right: 5%;
    background-position: center center;
  }
  .three {
    background-image: url('./images/JonRight.jpg');
    background-size: 150%;
    float: right;
    background-position: center center;
  }
  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }
  .two {
    width: 100%;
  }
  .card {
    background-color: #111;
    cursor: pointer;
    border: 1px solid #777;
    transition: all 0.3s;
    min-height: 250px; /* was 310px */
  }
  .card img {
    margin-top: -20px;
  }

  .cardBody p {
    font-size: 18px !important;
  }
  .cardHeader {
    min-height: 220px;
    overflow: hidden;
    border-radius: 10px;
  }
  .YTIframe {
    width: 100%;
    min-height: 400px;
  }
  .ls-5 {
    line-height: 1em !important;
    text-align: justify;
  }

  .containerBG {
    position: relative;
    width: 100%;
  }
  .btn-outline-light {
    font-family: 'Haettenschweiler', sans-serif;
    font-size: 20px;
    width: 200px !important;
  }
  .mobileIndieThieves h1 {
    font-size: 120px !important;
  }
  .buttonArea p {
    font-size: 0.7em;
  }
  .playlistButtonDescription {
    font-size: 20px;
  }

  h1.playlistButtonHeader {
    font-size: 3em;
    display: inline-block;
    top: 25px;
  }
  .playlistButtonDescription {
    color: whitesmoke;
    top: 25px;
  }
}
/* MD only ==================================================================================================================================*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .facebook {
    width: 30%;
    float: right;
  }

  .mailIcon {
    width: 35%;
    float: right;
    margin-right: -4px;
  }
  .socials h3 a {
    font-size: 2rem;
  }
  .headerText {
    font-size: 5rem;
  }
  .tallbucket {
    /* border: 3px solid red; */
    height: 200px;
    width: 100% !important;
  }
  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }

  .one {
    background-image: url('./images/TobyLeft.jpg');
    background-size: 90%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .two {
    background-image: url('./images/TobyAndJonMiddle.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 5%;
    background-position: center center;
  }
  .three {
    background-image: url('./images/JonRight.jpg');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .card {
    background-color: #111;
    cursor: pointer;
    border: 1px solid #777;
    transition: all 0.3s;
    min-height: 210px; /* was 310px */
  }
  .card img {
    margin-top: -20px;
  }

  .cardBody p {
    font-size: 18px !important;
  }
  .cardHeader {
    min-height: 220px;
    overflow: hidden;
    border-radius: 10px;
  }
  .YTIframe {
    width: 1100px;
    height: 600px;
  }
  .YTIframe {
    width: 100%;
    min-height: 400px;
  }

  .eventLocation {
    font-size: 14px;
  }

  .containerBG {
    position: relative;
    width: 100%;
  }
  .mobileIndieThieves h1 {
    font-size: 120px !important;
  }
  .buttonArea p {
    font-size: 0.7em;
  }

  .playlistButtonDescription {
    font-size: 20px;
    color: whitesmoke;
  }
  h1.playlistButtonHeader {
    font-size: 4.5em;
    display: inline-block;
  }
  h1.playlistButtonHeader {
    font-size: 4.5em;
    display: inline-block;
    top: 10px;
  }
  .playlistButtonDescription {
    color: whitesmoke;
    top: 30px;
  }
}
/* LG only ==============================================================================================================LG============================ */
@media (min-width: 992px) and (max-width: 1200px) {
  .facebook {
    width: 55%;
    float: right;
  }
  .eventContainer {
    width: 86%;

    margin: 50px;
  }
  .mailIcon {
    width: 65%;
    float: right;
    margin-right: -4px;
  }
  .socials h3 a {
    font-size: 2rem;
  }
  .headerText {
    font-size: 5rem;
  }

  .tallbucket {
    border: 3px solid red;
    height: 370px;
    width: 100% !important;
  }
  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }

  .one {
    background-image: url('./images/TobyLeft.jpg');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .two {
    background-image: url('./images/TobyAndJonMiddle.jpg');
    background-size: 115%;
    background-repeat: no-repeat;
    margin-right: 5%;
    background-position: center center;
  }
  .three {
    background-image: url('./images/JonRight.jpg');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .two,
  .one,
  .three {
    width: 100% !important;
    background-position: center center;
  }
  .YTIframe {
    width: 100%;
    min-height: 400px;
  }
  .mt-10 {
    margin-top: 200px !important;
  }
  .containerBG {
    position: relative;
    width: 100%;
    min-height: 90vh;
  }
  .backButton {
    position: absolute;
    left: 20px;
    top: 40px;
    width: 30px !important;
  }
  .card {
    background-color: #111;
    cursor: pointer;
    border: 1px solid #777;
    transition: all 0.3s;
    min-height: 250px; /* was 310px */
  }
  .card img {
    margin-top: -20px;
  }

  .cardBody p {
    font-size: 18px !important;
  }
  .cardHeader {
    min-height: 220px;
    overflow: hidden;
    border-radius: 10px;
  }
  .mobileIndieThieves h1 {
    font-size: 200px !important;
  }
  .buttonArea p {
    font-size: 0.8em;
  }
  h1.playlistButtonHeader {
    font-size: 4.5em;
    display: inline-block;
  }
  .playlistButtonDescription {
    font-size: 30px;
    color: whitesmoke;
  }
  h1.playlistButtonHeader {
    font-size: 4.5em;
    display: inline-block;
    top: 10px;
  }
  .playlistButtonDescription {
    color: whitesmoke;
    top: 30px;
  }
}
/* XL only ============================================================================================================================================*/
@media (min-width: 1200px) and (max-width: 1400px) {
  .facebook {
    width: 30%;
    float: right;
  }

  .mailIcon {
    width: 35%;
    float: right;
    margin-right: -4px;
  }
  .socials h3 a {
    font-size: 2rem;
  }
  .headerText {
    font-size: 5rem;
  }
  .tallbucket {
    border: 3px solid red;
    height: 500px;
    width: 100% !important;
  }
  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }

  .one {
    background-image: url('./images/TobyLeft.jpg');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .two {
    background-image: url('./images/TobyAndJonMiddle.jpg');
    background-size: 135%;
    background-repeat: no-repeat;
    margin-right: 5%;
    background-position: center center;
  }
  .three {
    background-image: url('./images/JonRight.jpg');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .two {
    width: 100% !important;
  }
  .card {
    background-color: #111;
    cursor: pointer;
    border: 1px solid #777;
    transition: all 0.3s;
    min-height: 260px; /* was 310px */
  }
  .card img {
    margin-top: -20px;
  }

  .cardBody p {
    font-size: 18px !important;
  }
  .cardHeader {
    min-height: 220px;
    overflow: hidden;
    border-radius: 10px;
  }
  .YTIframe {
    width: 100%;
    min-height: 600px;
  }
  .mt-10 {
    margin-top: 200px !important;
  }
  .containerBG {
    position: relative;
    width: 100%;
    min-height: 76vh;
  }

  .backButton {
    position: absolute;
    left: 20px;
    top: 40px;
  }

  .mobileIndieThieves h1 {
    font-size: 200px !important;
  }
  .buttonArea p {
    font-size: 0.9em;
  }
  h1.playlistButtonHeader {
    font-size: 4.5em;
    display: inline-block;
  }
  .playlistButtonDescription {
    color: whitesmoke;
  }

  h1.playlistButtonHeader {
    font-size: 4.5em;
    display: inline-block;
    top: 10px;
  }
  .playlistButtonDescription {
    color: whitesmoke;
    top: 30px;
  }
}
/* XXL only =========================================================================================================================================*/
@media (min-width: 1401px) {
  .facebook {
    width: 30%;
    float: right;
  }

  .mailIcon {
    width: 35%;
    float: right;
    margin-right: -4px;
  }
  .socials h3 a {
    font-size: 2rem;
  }
  .headerText {
    font-size: 5rem;
  }
  .tallbucket {
    border: 3px solid red;
    height: 650px;
    width: 100% !important;
  }
  .tallbucket h1 {
    text-align: center;
    font-size: 6rem;
    margin-top: 220px;
    text-shadow: #000 1px 0 10px;
  }

  .one {
    background-image: url('./images/TobyLeft.jpg');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .two {
    background-image: url('./images/TobyAndJonMiddle.jpg');
    background-size: 120%;
    background-repeat: no-repeat;
    margin-right: 5%;
    background-position: center center;
  }
  .three {
    background-image: url('./images/JonRight.jpg');
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .two {
    width: 100%;
  }
  .card {
    background-color: #111;
    cursor: pointer;
    border: 1px solid #777;
    transition: all 0.3s;
    min-height: 310px; /* was 310px */
  }
  .card img {
    margin-top: -20px;
  }

  .cardBody p {
    font-size: 18px !important;
  }
  .cardHeader {
    min-height: 220px;
    overflow: hidden;
    border-radius: 10px;
  }
  .YTIframe {
    width: 100%;
    min-height: 600px;
  }

  .mt-10 {
    margin-top: 200px !important;
  }
  .containerBG {
    position: relative;
    width: 100%;
    min-height: 85vh;
  }
  .backButton {
    position: absolute;
    left: 20px;
    top: 40px;
  }
  .buttonArea p {
    font-size: 2em;
  }

  h1.playlistButtonHeader {
    font-size: 4.5em;
    display: inline-block;
    top: 10px;
  }
  .playlistButtonDescription {
    color: whitesmoke;
    top: 30px;
  }
}
