@import url('https://fonts.cdnfonts.com/css/haettenschweiler');
@import url('mediaQueries.css');

body {
  color: white;
  background-color: black;
  height: 100vh;
 
}

.no-scrollbars {
  overflow: hidden;
  height:100vh!important;
}


* {
  user-select: none;
}
.offBlackBG {
  background-color: #0a0005;
  background-image: url('https://www.transparenttextures.com/patterns/black-twill.png');
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

h1,
h2,
h3 {
  font-family: 'Haettenschweiler', sans-serif;
  color: white;
}

p {
  /* font-family: 'Nanum Brush Script'; */
  font-family: 'Courier New', Courier, monospace;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-top: 10px;
}

a {
  text-decoration: none;
}

/* .btn {
  width: 180px !important;
} */
.whiteClickableLink {
  color: white;
}
.pr {
  position: relative;
}
.fixed {
  position: fixed;
}
.blackText {
  color: black;
}
.whiteText {
  color: white;
}
.text-center {
  text-align: center;
}

/* fonts */
.roboto {
  font-family: 'Courier New', Courier, monospace;
}
.ls-5 {
  line-height: 3rem;
}

.font-16 {
  font-size: 12px !important;
}
.font-21 {
  font-size: 21px;
}
.font-32 {
  font-size: 32px;
}
.font-27 {
  font-size: 27px;
}
.font-48 {
  font-size: 48px;
}
/* helpers  */
.mt-10 {
  margin-top: 100px;
}

.mb-10 {
  margin-bottom: 100px;
}

.align-right {
  text-align: right;
}

/* .the,
.the-s,
.indie,
.indie-s,
.thieves,
.thieves-s {
  position: absolute;
}

.the,
.the-s {
  left: 770px;
  top: 100px;
}

.indie,
.indie-s {
  left: 740px;
  top: 320px;
}

.thieves,
.thieves-s {
  left: 640px;
  top: 550px;
}

.the-s,
.indie-s,
.thieves-s {
  color: #555 !important;
  filter: blur(8px);
}
.the,
.indie,
.thieves {
  z-index: 99;
 transition: all 0.1s ease-in-out;
} */

.btn-outline-light {
  font-family: 'Haettenschweiler', sans-serif;
  font-size: 30px;
  width: 300px;
  margin-bottom: 10px;
}

.eventCardHeader {
  background-color: #000;
  width: 100%;
  margin-left: -35px;
  /* margin-right:-50px; */
  margin-top: -25px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  padding-left: 20px;
  font-size: 2em;
  font-family: 'Haettenschweiler', sans-serif;
}
.event {
  border-radius: 1em;
  background-color: #eee;
  border: 3px solid #ccc;
}

.next3gigBg {
  min-height: 100px;
  /* background-color: #ddd; */
  border-left: solid 1px #ccc;
}

.next3gigsLi {
  color: #555;
}
.nextGigHeader {
  font-size: 0.8em;
}
.nextGigText {
  font-size: 0.9em;
  color: #ccc;
}

.smallbucket {
  border: 3px solid red;
  height: 200px;
  width: 100% !important;
  text-align: center;
}
.smallbucket h1 {
  color: red;
  text-align: center;
  font-size: 96px;
  cursor: pointer;
}
h1.playlistButtonHeader,
.playlistButtonDescription {
  position: absolute;
  z-index: 9999;
  text-shadow: 0 0 15px #000;
}
.playlistButton {
  position: relative;
  /* overflow: hidden; */
  background-image: url('./images/buttonThumbsBW2.jpg');
  background-repeat: repeat-x;
  background-blend-mode: lighten;
  width: 100%;
  text-align: left;
  border: 6px solid #ccc;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  height: 124px;
  transition: 152s all;
}
.playlistButton:hover {
  /* transform:translateX(-800px) */
  background-position: -6000px 0;
}
.playlistButton::before {
  content: '';
  width: 100%;
  height: 112px;
  background: linear-gradient(0.25turn, rgba(0, 0, 0, 0), #000);

  z-index: 999;
  padding: 0;
  margin: -20px;
  margin-left: 80px;
}

/* .logoShadow {
  background-image: url('./images/');
  background-repeat: repeat-x;
  background-clip: text;
  color: transparent;
} */

.containerYTGallery {
  position: relative;
  width: 85%;
  min-height: 76vh;
}
.spinner-border {
  vertical-align: -12px;
  margin-left: 10px;
  border: 0.25em solid var(--velo-lightgrey);
  animation: 1.2s linear infinite spinner-border;
}

p {
  font-size: 2rem;
}

.st0 {
  font-family: 'Haettenschweiler';
}
.st1 {
  font-size: 150px;
  fill: fff;
}

.mobileIndieThieves h1 {
  text-align: center;
  font-size: 5rem;
  font-family: 'Haettenschweiler', sans-serif;
}

.logoTextContainer > h1 {
  text-align: center;
  color: white;
  font-family: 'Haettenschweiler', sans-serif;
  font-size: 12rem;
  padding: 5px;
  margin: 0;
  /* box-shadow: 5px 5px 4px 0px rgba(255,255,0, 0.75);*/
}

.pr {
  position: relative;
}

.socials h3 a {
  text-decoration: none;
  color: white;
}
.socials h3 a:hover {
  text-decoration: underline;
}

.footerSocials h3 {
  padding-top: 20px;
  padding-left: 20px;
}

.footerSocials h3 a {
  text-decoration: none;
  color: white;
}
.footerSocials h3 a:hover {
  text-decoration: underline;
}
.tallbucket {
  border: 3px solid white;
  transition: all 0.6s ease;
  width: 80%;
  overflow: hidden;
}

#left img,
#right img {
  width: 100%;
}
#left img {
  rotate: 2deg;
}
#right img {
  rotate: -2deg;
}
#left {
  margin-bottom: -10px;
}

.BioName {
  font-size: 3em;
}

.card h2,
.card p {
  color: white;
  padding: 5px;
}

.card:hover,
.card:focus {
  border: 1px solid rgb(233, 16, 16);
  cursor: pointer;
}
.card img {
  width: 100%;
}

.cardBody p {
  font-size: 1.2em;
  margin-top: -40px;
}

.videoCardsRow {
  --bs-gutter-y: 3rem;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.playlistTitle {
  color: black !important;
  font-size: 5em;
}

.YTModal {
  width: 1200px !important;
  min-height: 1000px !important;
  background-color: red;
}
.modal-content {
  border: 2px solid rgba(255, 0, 0, 0.534);
  background-color: #0a0005;
}
/* .modal-body {
  padding: 0 !important;
} */
.modal-header .btn-close {
  color: red !important;
  border: 2px solid red;
  background-color: red;
}
.modal-header {
  border: none !important;
}

.GalleryHeader {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
}

.GalleryHeader p,
.GalleryHeader h1 {
  color: black;
  padding-left: 20px;
}
.GalleryHeader p {
  font-size: 1.6rem;
}

.blackBackButton {
  background-color: #000;
  height: 4em;
  padding: 11px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12), 0 3px 4px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.blackBackButton:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.setCursor {
  cursor: pointer;
}

#footer {
  border-top: 1px solid #777;
  min-height: 100px;
}

#subfooter {
  background-color: #555;
  height: 30px;
}

.credits {
  font-size: 14px;
  float: right;
  padding-top: 5px;
}
.credits a {
  color: #ddd;
}

.credits a:hover {
  color: #fff;
}

.mediaCardHeader.mediaCardFooter {
  min-height: 40px;
  border-bottom: 1px solid white;
}
.mediaCardBody {
  overflow: hidden;
  padding-top: 50px;
}
.mediaCardBody p {
  border-top: 1px solid white;
  margin-top: 10px;
  font-size: 16px;
}
.mediaCardFooter {
  min-height: 40px;
}
.footerDLButton {
  width: 95% !important;
  font-size: 80% !important;
}

.logoSVG {
  height: 100px;
  width: 100px;
  fill: #fff;
}
/* .newHeader {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(4, 19vh);
  position: relative;
 
  min-height: 400px !important;
}

.mainArea {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 5;
  align-items: center;
  align-content: center;
  min-height: 400px !important;
  position: relative;

}

.mainArea img {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
}

.buttonArea1 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}
.buttonArea2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}
.buttonArea3 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}
.buttonArea4 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 5;
}

@media (max-width: 991.98px) {
  .mobileIndieThieves svg {
    fill: #fff;

    transform: scale(0.5);

    width: 1000px;
    height: 100px;
    padding-top: 20px;
    border: 3px solid rebeccapurple;
  }
  .playlistButtonDescription {
    color: whitesmoke !important;
  }
  .newHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 416px 200px 200px;
    position: relative;

    max-height: 50vh;
    margin-bottom: 400px;
  }

  .mainArea {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
    overflow: hidden;

  }
  .buttonArea1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  .buttonArea2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  .buttonArea3 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
  }
  .buttonArea4 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }
}

.buttonArea a {
  color: #000;
  transition: all 0.4s;
}

.buttonArea {
  border: 3px solid transparent;
  transition: all 0.6s;
  text-align: center;
  padding-top: 8%;
  color: rgba(255, 255, 255, 0.12);
  font-size: 24px;
  padding-left: 10px;
}
.buttonArea:hover {
  border: 3px solid #fff;
  color: rgba(255, 255, 255, 1);
}
.buttonArea h1 {
  font-size: 80px;
  color: rgba(255, 255, 255, 0.72);
}

.buttonArea h1:hover {
  color: rgba(255, 255, 255, 1);
}

.buttonArea a:hover {
  color: rgba(255, 255, 255, 1);
} */

/* ------------------------------------------------------------------------------ */
/* BIOS STYLING */
#biosWrapper {
  /* border: 1px solid red; */
  height: 750px;
  position: relative;
}
#tobyMain,
#jonMain {
  width: 512px;
  height: 750px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0px;
}
#jonMain {
  background-image: url(./images/BIOS/JonMain.jpg);
  right: 0;
}

#tobyMain {
  background-image: url(./images/BIOS/TobyMain.jpg);
  left: 0;
}

.clickforthief {
  position: absolute;
  bottom: 0;
  font-size: 1.2em;
}

#slider {
  width: 1200px;
  padding: 50px;
  margin: 0;
  position: absolute;
  border-radius: 15px;
  top: -15px;
  bottom: -15px;
  background-color: #111;
  box-shadow: 1px 1px 15px 1px rgba(214, 214, 214, 1);
  transition: all 0.6s ease-in-out;
}

.tobyPeeping {
  position: absolute;
  left: -114px;
  scale: 0.6;
  top: 100px;
  width: 50px;
}

.jonPeeping {
  position: absolute;
  right: -30px;
  top: 100px;
  scale: 0.6;
  width: 50px;
}

.slidePositionLeft {
  left: 200px;
}
.slidePositionRight {
  left: 500px;
}

.jonText {
  text-align: right;
}

.sliderThumb {
  border: 2px solid whitesmoke;
  height: 32%;
  margin-bottom: 3%;
  overflow: hidden;
}

.slider-thumbs-wrapper {
  /* border:1px solid green; */
  height: 720px;
}

.slider-thumb-image {
  aspect-ratio: 4/3;
}

.MobileBioThief {
  width: 100%;
  aspect-ratio: 4/6;
  margin-bottom: 10px;
}
.mobileTheifBioText p {
  font-size: 0.8em;
}
.mobileTheifBioText h1 {
  font-size: 1em;
}
.mobilethiefthumbs img {
  width: 100%;
}

/* Media queries for bios slider only */

/* end bios styling */

/* //new header */
.IndieTheievesHerologo {
  display: flex;
  width: clamp(100px, 50vw, 500px);
  margin-left: auto;
  margin-right: auto;
}
.fullWidthGridLayout {
  display: grid;
  grid-template-columns: 1.3fr 5fr 2fr;
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
  margin: 10px;
  height: 70vh;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}

.fullWidthGridLayout div {
  overflow: hidden;
}

.gridItem_1 {
  /*FB link */
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  /* background-color: teal; */
}

.gridItem_2 {
  display: grid;
  align-items: center;
  /*Main hero area */
  grid-column: 2 / 3;
  grid-row: 1 / 5;
  /* background-color: aqua; */
}

.gridItem_3 {
  /*gigs button */
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  /* background-color: yellow; */
}

.gridItem_4 {
  /*insta button */
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  /* background-color: rgb(233, 16, 16); */
}

.gridItem_5 {
  /*Videos button */
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  /* background-color: rgba(18, 201, 18, 0.72); */
}

.gridItem_6 {
  /*youtube button */
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  /* background-color: blueviolet; */
}

.gridItem_7 {
  /*About button */
  grid-column: 3/4;
  grid-row: 3/4;
  /* background-color: salmon; */
}

.gridItem_8 {
  /*Email button */
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  /* background-color: blue; */
}

.gridItem_9 {
  /*Media button */
  grid-column: 3 / 4;
  grid-row: 4 / 5;
  /* background-color: rosybrown; */
}

.headerGridButton p {
  opacity: 0;
  margin-top: -10px;
  transition: all 0.4s;
}

.headerGridButton:hover p {
  opacity: 1;
  color: white;
  margin-top: 0px;
  font-size: clamp(0.8rem, 1vw, 1.2rem);
}
/*  smaller grid ************************************************************************** */
@media (max-width: 991.98px) {
  .fullWidthGridLayout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 4fr 0.75fr 1fr 1fr;
    gap: 10px;
    margin: 10px;
    height: 70vh;
  }

  .gridItem_1 {
    /*FB link */
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    /*background-color: teal;*/
    text-align: center;
  }

  .gridItem_2 {
    display: grid;
    align-items: center;
    /*Main hero area */
    grid-column: 1 / 5;
    grid-row: 1 / 2;
    /*background-color: aqua;*/
  }

  .gridItem_3 {
    /*gigs button */
    grid-column: 1/3;
    grid-row: 3 / 4;
    /*background-color: yellow;*/
  }

  .gridItem_4 {
    /*insta button */
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    /*background-color: rgb(233, 16, 16);*/
    text-align: center;
  }

  .gridItem_5 {
    /*Videos button */
    grid-column: 3 / 5;
    grid-row: 3/4;
    /*background-color: rgba(18, 201, 18, 0.72);*/
  }

  .gridItem_6 {
    /*youtube button */
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    /*background-color: blueviolet;*/
    text-align: center;
  }

  .gridItem_7 {
    /*About button */
    grid-column: 1/3;
    grid-row: 4/5;
    /*background-color: salmon;*/
  }

  .gridItem_8 {
    /*Email button */
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    /*background-color: blue;*/
    text-align: center;
  }

  .gridItem_9 {
    /*Media button */
    grid-column: 3 / 5;
    grid-row: 4 / 5;
    /*background-color: rosybrown;*/
  }

  .headerGridButton {
    padding: 10px;
    text-align: center;
  }

  .headerGridButton {
    padding: 10px;
    text-align: center;
  }

  .gridItem_2 img {
    width: 40%;
  }
}
/*  End smaller grid ************************************************************************** */

.logoVideoGallery {
  width: 100%;
  max-width: 100px;
}

/* Facebook follower count page  */

/* .centered {
  height: 100vh;
} */

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  padding: 10px;
}

.centered h1 {
  font-size: clamp(2rem, 60vw, 80rem);
  text-align: center;
  line-height: 1;
  margin: 0;
}

@keyframes flashAndGrow {
  0% {
    color: red;
    transform: scale(0.3) rotate(10deg);
  }
  25% {
    color: orange;
    transform: scale(1.5);
  }
  50% {
    color: yellow;
    transform: scale(0.6) rotate(-10deg);
  }
  75% {
    color: green;
    transform: scale(1.6);
  }
  100% {
    color: blue;
    transform: scale(1);
  }
}

.animate {
  animation: flashAndGrow 1s 5;
}

.social-svg-icon {
  transition: scale 0.3s;
  display: inline-block;
}
.social-svg-icon :hover {
  fill: grey;
  scale: 1.01;
}

.pull-right {
  float: right;
  padding-right: 10px;
  padding-top: 20px;
}

.no-scrollbars {
  position: relative;
}

.offlineIndicator {
  position: fixed;
  top: 50px;
  left:50px;
  width:10px;
  height:10px;
  margin: 10px;
  z-index: 99999;
}